import enquireJs from 'enquire.js'

export function isDef (v){
  return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove (arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item)
    if (index > -1) {
      return arr.splice(index, 1)
    }
  }
}

export function isRegExp (v) {
  return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true)
    },
    unmatch: function () {
      call && call(false)
    }
  }
  enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

//  // 秒转时分秒
// export function totalTime(time) {
//   if (time) {
//     if (time > 60) {
//       if (time > 3600) {
//         const hours = Math.floor(time / 3600);
//         const minutes = Math.floor((time % 3600) / 60);
//         time = `${hours}小时${minutes}分钟`;
//       } else {
//         const minutes = Math.floor(time / 60);
//         time = `${minutes}分钟`;
//       }
//     } else {
//       const seconds = Math.floor(time);
//       time = `${seconds.toFixed()}秒`;
//     }
//     return time;
//   } else {
//     return 0;
//   }
// }
// 秒转换为天、小时、秒
export function totalTime(time) {
  if (time) {
    if (time >= 60) {
      if (time >= 3600) {
        if (time >= 86400) {
          const days = Math.floor(time / 86400);
          const remainingHours = Math.floor((time % 86400) / 3600);
          const remainingMinutes = Math.floor(((time % 86400) % 3600) / 60);
          const seconds = Math.floor(((time % 86400) % 3600) % 60);
          return `${days}天${remainingHours}小时${remainingMinutes}分钟${seconds}秒`;
        } else {
          const hours = Math.floor(time / 3600);
          const remainingMinutes = time % 3600 / 60;
          const minutes = Math.floor(remainingMinutes);
          const seconds = Math.floor((remainingMinutes - minutes) * 60);
          return `${hours}小时${minutes}分钟${seconds}秒`;
        }
      } else {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}分钟${seconds}秒`;
      }
    } else {
      return `${Math.floor(time)}秒`;
    }
  } else {
    return '0';
  }
}


const _toString = Object.prototype.toString
