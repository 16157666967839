<template>
  <div class="index-page">
    <a-config-provider :locale="locale" :get-popup-container="popContainer">
      <router-view v-if="isRouterAlive" />
    </a-config-provider>
  </div>
</template>

<script>
import { enquireScreen } from './utils/util'
import { mapState, mapMutations } from 'vuex'
import themeUtil from '@/utils/themeUtil';
import { getI18nKey } from '@/utils/routerUtil'
import {request} from '@/utils/request'

import { getRoutesConfig } from '@/services/user'
import { setAuthorization } from '@/utils/request'
import { loadRoutes } from '@/utils/routerUtil'
import routerMap from '@/router/async/router.map'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      locale: {},
      ws: null,
      isRouterAlive: true
    }
  },
  created() {
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
   
  },
  mounted() {
    // 获取用户信息
    this.getUserInfo();

    this.setWeekModeTheme(this.weekMode)

    this.getFingMsg()
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function () {
      window.dispatchEvent(new Event('resize'))
    }
  },
  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang'],'account',['user']),
  },
  beforeDestroy() {
  },
  methods: {
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
    getUserInfo(){
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/login', 'get').then(res => {
        this.afterLogin(res);
      })
    },
    afterLogin(res) {
      this.logging = false
      const loginRes = res.data
      if (loginRes.code == 200) {
        res.data.permissions = [{ "id": "queryForm", "operation": loginRes.data.authorityList }]
        res.data.roles = [{ "id": "admin", "operation": loginRes.data.menuList }]
        this.$store.commit('account/setPermissions', { "id": "queryForm", "operation": loginRes.data.authorityList })
        this.setUser(loginRes.data)
        this.setPermissions(loginRes.permissions)
        this.setRoles(loginRes.roles)
        setAuthorization({ token: loginRes.data.token, expireAt: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) })
        let routesList = [], selectRoutes = []
        for (var key in routerMap) {
          routesList.push(key)
        }
        // 获取路由配置 
        getRoutesConfig().then(result => {
          let routesChildren = result.data.data;
          routesChildren.forEach(item => {
            routesList.forEach(son => {
              if (item.code == son) {
                if (item.childrenList) {
                  selectRoutes.push({
                    router: item.code,
                    children: item.childrenList
                  })
                } else {
                  selectRoutes.push({
                    router: item.code
                  })
                }
              }
            })
          })
          selectRoutes.forEach(item => {
            if (item.children) {
              item.children.forEach((son, index) => {
                let obj = {
                  router: son.code,
                  path: son.code
                }
                
                if (son.code == 'sfa_demand_scheme' ||son.code == 'sfa_demand_details' ||son.code == 'sfa_demand_add' || son.code == 'addForm' || son.code == 'Contact' || son.code == 'InvoiceList' || son.code == 'ContactAddress' || son.code == 'query_form' || son.code == 'addWorkOrder'|| son.code == 'detailsWorkOrder'|| son.code == 'editWorkOrder' || son.code == 'sfa_customer_details' || son.code == 'addInvoiceApply' || son.code == 'editInvoiceApply') {
                  obj.invisible = true;
                }

                item.children[index] = obj
              })
            }
          })
          const order = ['dashboard','sfa_sales_programs', 'sfa_commodity_management', 'sfa_customer', 'sfa_goOut','sfa_telephoneRecord','sfa_financial','sfa_resource', 'sfa_workOrder','sfa_mine'];
          selectRoutes.sort((a, b) => {
            const indexA = order.indexOf(a.router);
            const indexB = order.indexOf(b.router);
            return indexA - indexB;
          });
          selectRoutes.unshift({"router":"dashboard","children":["workplace"]})
          const routesConfig = [{
            router: 'root',
            children: selectRoutes
          }]
          console.log(routesConfig,'routesConfig')
          console.log(selectRoutes,'selectRoutes')
          loadRoutes(routesConfig)
        })
      } else {
        this.error = loginRes.message
      }
    },
    // 公共通知
    getFingMsg(){
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/findReturnMessage', 'post',{}).then(res => {
        this.findMsg = res.data.data;
        this.findMsg.forEach(item=>{
          this.$notification['info']({
              message: '通知',
              description: item.messageContent,
              duration: null,
              key: item.id, // 指定通知的唯一 key
              onClose: () => {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateReturnMessage', 'post',{sourceId:item.sourceId})
              },
            });
        })
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/findExpectReturnMessage', 'post',{}).then(res => {
        this.findExpMsg = res.data.data;
        this.findExpMsg.forEach(item=>{
            this.$notification['warn']({
              message: '通知',
            description: item.messageContent,
            duration: null,
            key: item.id, // 指定通知的唯一 key
            onClose: () => {
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateExpectReturnMessage', 'post',{sourceId:item.sourceId})
            },
          });
        })
      })
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    },
    ...mapMutations('setting', ['setDevice']),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
        case 'CN':
          this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
          break
        case 'HK':
          this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
          break
        case 'US':
        default:
          this.locale = require('ant-design-vue/es/locale-provider/en_US').default
          break
      }
    },
    setHtmlTitle() {
      const route = this.$route
      const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
      document.title = process.env.VUE_APP_NAME + ' | ' + this.$t(key)
    },
    popContainer() {
      return document.getElementById("popContainer")
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

::v-deep .ant-card-bordered {
  border: none !important;
}

.ant-card-bordered {
  border: none !important;
}

::v-deep .ant-layout-header {
  height: 44px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text-wrap2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 控制显示的行数 */
  overflow: hidden;
}



</style>
