//跨域代理前缀
const API_PROXY_PREFIX='/api/'
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
// const BASE_URL = process.env.VUE_APP_API_BASE_URL
module.exports = {
  LOGIN: `${BASE_URL}sfa/login`,
  ROUTES: `${BASE_URL}sfa/menu/list/all`,
  GOODS: `${BASE_URL}sfa/goods`,
  GOODS_COLUMNS: `${BASE_URL}sfa/columns`,
  PROGLIST:`${BASE_URL}sfa/programme/list`
}
